<template>
    <div style="margin:0 10px">
        <router-view></router-view> 
        <div class="card">
            <!-- search -->
            <div class="search d-flex a-center j-sb">
                <div class="d-flex a-center">
                    <div style="width:160px;margin-right:10px;">
                        <el-input type="text" v-model="query.a" placeholder="评课标题"></el-input>  
                    </div>
                    <el-button type="primary" @click="init">查询</el-button>
                    <el-button type="primary" @click="$router.push({name:'auto_add'})">+ 添加</el-button>    
                </div>

            </div>

            <!-- handle -->
            <div class="handle"></div>

            <!-- table -->
            <div class="table" style="margin-top:20px;">
                <el-table
                    :data="tableData"
                    style="width: 100%;"
                    v-loading="loading"
                    border
                    element-loading-background="rgba(255,255,255, 0.6)"
                    :header-cell-style="{background:'#f8f8f9',color:'#606266',borderBottom:'1px solid #e8eaec',padding:'8px 0',fontWeight:'700'}"
                >
                    <el-table-column prop="title" label="评课标题" align="center"></el-table-column>
                    <el-table-column prop="subjectName" label="学科" align="center"></el-table-column>
                    <el-table-column prop="className" label="班级" align="center"></el-table-column>
                    <el-table-column prop="courseName" label="课程名称" align="center"></el-table-column>
                    <!-- <el-table-column prop="" label="评课类型" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.reviewType == 1">AI评价</span>
                            <span v-else-if="scope.row.reviewType == 2">专家评</span>
                            <span v-else-if="scope.row.reviewType == 3">人机协同</span>
                            <span v-else>无</span>
                        </template>
                        
                    </el-table-column> -->
                    <el-table-column prop="" label="评课方式" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.type == 1">视频</span>
                            <span v-else-if="scope.row.type == 2">直播</span>
                            <span v-else>无</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="teacherName" label="教学老师" align="center"></el-table-column>
                    <el-table-column prop="templateName" label="评课模板" align="center"></el-table-column>
                    <el-table-column prop="liveStartTime" label="开始时间" align="center"></el-table-column>
                    <el-table-column prop="liveEndTime" label="结束时间" align="center"></el-table-column>
                    <el-table-column prop="reviewTime" label="评课时间" align="center"></el-table-column>
                    <el-table-column prop="" label="评课状态" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.reviewState == 1">正在评课</span>
                            <span v-else-if="scope.row.reviewState == 2">评课完成</span>
                            <span v-else-if="scope.row.reviewState == -1">评课失败</span>
                            <span v-else-if="scope.row.reviewState == 0">等待评课</span>
                            <span v-else> - </span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="" fixed="right"  label="操作" align="center">
                        <template slot-scope="scope">
                            <el-dropdown @command="(command)=>handleMore(command,scope.row)">
                                <span class="el-dropdown-link">
                                    管理<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item command="start" v-if="$store.state.menus.routerName.includes('screen_lesson')">开始评课</el-dropdown-item>
                                    <el-dropdown-item command="stop" v-if="$store.state.menus.routerName.includes('lesson:repeatReview')">重新评课</el-dropdown-item>
                                    <el-dropdown-item command="report" v-if="$store.state.menus.routerName.includes('screen_report')">评课结果</el-dropdown-item>
                                    <el-dropdown-item command="class_portrait" v-if="$store.state.menus.routerName.includes('screen_class')">课程画像信息</el-dropdown-item>
                                    <el-dropdown-item command="teacher_portrait" v-if="$store.state.menus.routerName.includes('screen_teacher')">教师画像信息</el-dropdown-item>
                                    <el-dropdown-item command="edit" v-if="$store.state.menus.routerName.includes('auto_edit')">编辑</el-dropdown-item>
                                    <el-dropdown-item command="del" v-if="$store.state.menus.routerName.includes('auto_del')">删除</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                            
                            
                            
                            
                        </template>
                    </el-table-column>
                </el-table>

                <!-- page -->
                <div style="margin-top:20px;text-align:center">
                    <el-pagination
                        background
                        layout="total, prev, pager, next, sizes"
                        :total="total"
                        :page-sizes="pageSizes"
                        :page-size="query.size"
                        @size-change="onSize"
                        @current-change="onCurrPage"
                        @prev-click="onCurrPage"
                        @next-click="onCurrPage"
                    >
                    </el-pagination>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import {lists,del,lesson_start,lesson_review} from '@/request/api/lession.js'
export default {
    data(){
        return {
            tableData: [],
            query:{keyword:'',page:1,size:10},
            pageSizes:[10,30,50,100],
            total:0,
            loading:false,
            isEdit:false ,//编辑状态
        }
    },
    watch:{
        $route(val ,old){
            //返回刷新
            if(val.name=='auto') {
                this.init()
            }
        }
    },
    created(){ 
        this.init()
    },

    methods:{
        init(){
            lists(this.query).then(res=>{
                this.tableData = res.data.list
                this.total = res.data.total
            })
        },
        handleMore(command,data){
            switch(command) {
                case 'start':
                    lesson_start(data.id).then(res=>{
                        
                        if(res.code!=0 && data.type==1) {
                            return this.$alert(res.msg, '提示', {confirmButtonText: '我已知晓'});
                        }

                        if(res.code!=0) return this.$notify({title: '失败',message:res.msg,type: 'error'});
                        if(data.reviewType == 1) {
                            //AI评价
                            this.$notify({title: '成功',message: "SUCCESS",type: 'success'});
                            this.init()
                            return;
                        }
                        this.$router.push({name:'screen_lesson',query:{template_id:data.templateId,lesson_id:data.id,teacher_id:data.teacherId}})
                    })
                    break;
                case "stop":
                    this.$confirm('是否确定重新确定？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        lesson_review(data.id).then(res=>{
                            if(res.code!=0) return this.$notify({title: '失败',message:res.msg,type: 'error'});
                            if(data.reviewType == 1) {
                                //AI评价
                                this.$notify({title: '成功',message: "SUCCESS",type: 'success'});
                                this.init()
                                return;
                            }
                            this.$router.push({name:'screen_lesson',query:{id:data.templateId,lesson_id:data.id}})
                        })
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除'
                        });          
                    });
                    break;

                case "report":
                    this.$router.push({name:'screen_report',query:{teacher_id:data.teacherId,lesson_id:data.id}})
                    break;
                case "teacher_portrait":
                    this.$router.push({name:'screen_teacher',query:{teacher_id:data.teacherId,lesson_id:data.id}})
                    break;

                case "class_portrait":
                    this.$router.push({name:'screen_class',query:{teacher_id:data.teacherId,lesson_id:data.id}})
                    break;

                case "edit":
                    this.$router.push({name:'auto_edit',query:{id:data.id}})
                    break;

                case "del":
                    this.$confirm('确认删除？').then(_ => {
                        //确认删除
                        del({ids:[data.id]}).then(res=>{
                            if(res.code!=0) return this.$notify({title: '失败',message:res.msg,type: 'error'});
                            this.init()
                            this.$notify({title: '成功',message: "SUCCESS",type: 'success'});
                            
                        })
                    })
                    .catch(_ => {});
                    break;  
                default:
                    break;
            }
        },
        //每页条数
        onSize(val){
            this.query.size=val
            this.init()
        },
        onCurrPage(val){
            this.query.page = val
            this.init()
        }
    }
}
</script>


<style scoped lang="less">
 .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
</style>

