<template>
   <div class="screen_main">
        <div class="mob_nav"><MobNav /></div>
        <div class="d-flex h-full">
            <el-row class="flex-1" :gutter="10" style="height: 100%;">
                <el-col :md="14" style="height: 98%;margin-bottom: 1%;">
                    <div style="background-color: #fff;height: 100%;" class="d-flex flex-column">
                        <div v-if="!video.url" class="seui-adblock">
                            <div class="btn-row">
                                <button class="upload-btn md-show">
                                    <label for="file-upload" class="upload-btn-button">
                                    <div class="icon-bg"></div>

                                    <span>选择文件</span>
                                    </label>
                                    <input @change="uploadChange" id="file-upload" type="file" style="display: none;" />
                                    <span id="file-name"></span>
                                </button>

                                <!-- <button class="upload-btn">
                                    <div class="icon-bg"></div>
                                    <span>本地资源</span>
                                </button> -->
                                <button class="upload-btn-color" @click="isSearch=true">
                                    <div class="icon-bg"></div>
                                    <span>在线资源</span>
                                </button>
                            </div>
                        </div>
                        <div v-else>
                            <AliPlayerVOD ref="alipayvodEl" v-if="!video.live" :url="video.url"></AliPlayerVOD>
                            <AliPlayer v-else :url="video.url"/>
                            <!--<VideoPlay v-else ref="videos" :live="video.live" :url="video.url"/>-->
                        </div>

                        <div class="d-flex j-sb" style="padding: 10px;">
                            <div>
                                <span style="font-size: 14px;padding-left: 10px;border-left: 4px solid #0886da;">过程记录</span>
                            </div>

                            <el-button type="primary" size="mini" @click="handleHistory">新建记录</el-button>
                        </div>

                        <div class="flex-1 overflow" style="margin: 0 10px;">
                            <overlay-scrollbars style="width: 100%;height: 100%;" class="el-fade-in">
                                <div class="speak position-relative" v-for="item,index in form.logs" :key="index">
                                    <div class="clear" @click="handleClear(index)">移除</div>
                                    <div class="tool">{{ Math.floor(item.position/60/60) }}小时 {{ Math.floor(item.position/60) }}分 {{ item.position%60 }}秒</div>
                                    <div class="">
                                        <el-input type="texteare" placeholder="说点什么" v-model="item.content"></el-input>
                                    </div>
                                </div>
                            </overlay-scrollbars>
                        </div>
                    </div>
                </el-col>
                <el-col :md="10" style="height: 98%;margin-bottom: 1%;" v-if="form.lessonId">
                    <div class="d-flex flex-column" style="height: 100%;background-color: #fff;padding: 10px;box-sizing: border-box;">
                        <div class="line" style="border-left: 4px solid #0886da;padding-left: 10px;margin-bottom: 10px;font-size: 14px;">评课维度</div>
                        <div class="flex-1">
                            <overlay-scrollbars style="width: 100%;height: 100%;" class="el-fade-in">
                                <Group v-for="item,index in rules" :key="index" 
                                    :name="item.groupName"
                                    :list="item.children"
                                    :open="true"
                                    :score="item.score"
                                    :color="$store.state.dict.color[index%$store.state.dict.color.length]"
                                    @changeRule="(val)=>changeRule(val,item,index)"
                                />
                                <div style="height: 70px;"></div>
                            </overlay-scrollbars>
                        </div>
                    </div>
                </el-col>
                
            </el-row>
            <div style="width: 50px;margin-left: 10px;" class="screen_nav d-flex a-center">
                <Nav @change="handleNav" :params="{lesson_id:form.lessonId,teacher_id:teacher_id}"/>
            </div>
        </div>
        <!-- mobile -->
        <div class="mobile_submit d-flex items-center">
            <div class="flex-1 d-flex a-center j-center"><el-button @click="handleNav('clear')" type="primary" round plain>清除当前课程</el-button></div>
            <div class="flex-1 d-flex a-center j-center"><el-button @click="handleNav('save')" type="primary" round>保存评课信息</el-button></div>
        </div>
        

        <el-dialog title="选择课程" :visible.sync="isSearch" :modal="false">
            <div class="search d-flex a-center j-sb">
                <div class="d-flex a-center">
                    <div style="width:160px;margin-right:10px;">
                        <el-input type="text" v-model="query.keyword" placeholder="评课标题" clearable></el-input>  
                    </div>
                    <el-button type="primary" @click="lesson_lists">查询</el-button>
                </div>
            </div>

            <el-table :data="tableData">
                <el-table-column prop="title" label="评课标题" align="center"></el-table-column>
                    <el-table-column prop="subjectName" label="学科" align="center"></el-table-column>
                    <el-table-column prop="className" label="班级" align="center"></el-table-column>
                    <el-table-column prop="courseName" label="课程名称" align="center"></el-table-column>
                    <el-table-column prop="" label="评课类型" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.reviewType == 1">AI评价</span>
                            <span v-else-if="scope.row.reviewType == 2">专家评</span>
                            <span v-else-if="scope.row.reviewType == 3">人机协同</span>
                            <span v-else>无</span>
                        </template>
                        
                    </el-table-column>
                    <el-table-column prop="" label="评课方式" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.type == 1">视频</span>
                            <span v-else-if="scope.row.type == 2">直播</span>
                            <span v-else>无</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="teacherName" label="教学老师" align="center"></el-table-column>
                    <el-table-column prop="templateName" label="评课模板" align="center"></el-table-column>
                    <el-table-column prop="" label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button type="primary" size="mini" @click="handleUseLesson(scope.row)">使用</el-button>
                        </template>
                    </el-table-column>
            </el-table>
            <div style="margin-top:20px;text-align:center">
                <el-pagination
                    background
                    layout="total, prev, pager, next, sizes"
                    :total="total"
                    :page-sizes="pageSizes"
                    :page-size="query.size"
                    @size-change="onSize"
                    @current-change="onCurrPage"
                    @prev-click="onCurrPage"
                    @next-click="onCurrPage"
                >
                </el-pagination>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import Group from './comp/group.vue'
import {computeScore} from '@/global/utils'
import {lesson_submit,template,get,lists,lesson_video,reviewRecord} from '@/request/api/lession.js'
import Nav from './comp/nav.vue'
import MobNav from '@/components/MobileMenu.vue'
import AliPlayer from './comp/AliPlayer.vue'
import AliPlayerVOD from './comp/AliplayerVOD.vue'
// import VideoPlay from './comp/video-play.vue'
import moment from 'moment'
export default {
    components:{
        Group,
        Nav,
        MobNav,
        AliPlayerVOD,
        AliPlayer
    },
    data(){
        return{
            template_id:'',
            rules:[],//评课组
            teacher_id:'',
            form:{
                lessonId:'',
                logs:[],
                rules:[]
            },
            query:{keyword:'',page:1,size:10},
            pageSizes:[10,30,50,100],
            total:0,
            tableData:[],
            video:{
                live:false,
                url:null,
                start_time:null,
                end_time:null
            },
            complexRules:[],
            expertRules:[],
            isSearch:false,// search status
        }
    },
    created(){
        this.template_id = this.$route.query.template_id || ''
        this.form.lessonId = this.$route.query.lesson_id || ''
        this.teacher_id = this.$route.query.teacher_id || ''
        this.init()
        
    },
    watch:{
        "$route":function(val){
            this.template_id = val.query.template_id || ''
            this.form.lessonId = val.query.lesson_id || ''
            this.teacher_id = val.query.teacher_id || ''
            this.video.url = null
            this.init()
        }
    },
    mounted(){
    },
    methods:{
        init(){
            this.lesson_lists()
            if(!this.form.lessonId) return ;
            template(this.form.lessonId).then(res=>{
                res.data.rules && res.data.rules.forEach(item=>{
                    item.children && item.children.forEach(itx=>{
                        itx['max']=0
                    })
                })
                this.rules = res.data.rules
            })

            //获取视频地址
            this.getLesson()    
            this.getReviewRecord()

            //在课程画像里拿视频记录。
            // portrait(this.form.lessonId).then(res=>{
            //     this.video.start_time = res.res.data.lesson.liveStartTime
            //     this.video.end_time = res.res.data.lesson.liveEndTime
            //     this.form.logs = res.logs
            //     this.complexRules = res.complexRules
            //     this.expertRules = res.expertRules
            //     this.setRules()
            // })
            
        },

        

        //获取自己的打分
        async getReviewRecord(){
            let {rules,logs} = await reviewRecord(this.form.lessonId)
            this.form.logs = logs
            this.form.rules = rules
            this.complexRules =rules
            this.expertRules = rules
            this.setRules()
        },

        //课程详情 
        getLesson(){
            get(this.form.lessonId).then(res=>{
                this.video.start_time = res.data.liveStartTime
                this.video.end_time = res.data.liveEndTime
                this.getCloudVideo()
            })
        },

        getCloudVideo(){
            lesson_video(this.form.lessonId).then(res=>{
                if(res.code!=0) return this.$notify({title: '失败',message:res.msg,type: 'error'})
                if(res.data.videoUrl) {
                    this.video.live=false
                    this.video.url = res.data.videoUrl
                }else{   
                    this.video.live=true
                    this.video.url = res.data.liveUrl
                }
            })
        },
       

        lesson_lists(){
            lists(this.query).then(res=>{
                this.tableData = res.data.list
                this.total = res.data.total
            })
        },

        uploadChange(){
            var f = document.getElementById('file-upload').files[0]
            this.video.url = URL.createObjectURL(f)
            this.video.live = false
        },
        handleHistory(){
            let time = null
            if(!this.video.live) {
                
                time = Math.floor(this.$refs.alipayvodEl.getCurrentTime())
            }else{
                //直播
                var startDate = moment(this.video.start_time)
                var start_time = startDate.valueOf()
                var current_time = Date.now()
                time = Math.floor( (current_time - start_time) / 1000 )
                
            }
            let obj = {
                content:'',
                lessonId:this.form.lessonId,
                position:time
            }
            this.form.logs.push(obj)
        },

        //赋值评课维度
        setRules(){
            if(!this.expertRules) return ;
            this.rules.forEach(obj=>{
                let item = this.expertRules.find(item=>item.id==obj.id)
                if(item){
                    obj.score = item.score
                    obj.children.forEach(el=>{
                        //指标项
                        let itx_data = item.children.find(elx=>elx.id==el.id)
                        Object.keys(el).forEach(key=>{
                            el[key] = itx_data[key]
                        })
                    })
                }
            })
        },

        //清空评课维度
        clearRules(){
            this.rules.forEach(obj=>{
                obj.score = null
                obj.children.forEach(el=>{
                    el.score = null
                    el.max=0
                })
            })
        },

        changeRule(val,objects,index){
            let score = computeScore(val.item.maxScore,val.val)
            var arr = 0;
            this.rules[index].children.forEach(item=>{
                if(item.id == val.item.id) {
                    item.max = val.val
                    item.score = score
                }
                arr += item.score 
            })
            this.rules[index].score = arr.toFixed(2)*1;

        },

        req_save(){
            var obj = []
            var _this = this
            this.rules.forEach(item=>{
                let items = {id:item.id,list:[]}
                
                item.children && item.children.forEach(itx=>{
                    items.list.push({id:itx.id,score:itx.score})
                })
                obj.push(items)
            })
            this.form.rules = obj
            lesson_submit(this.form).then(res=>{
                if(res.code!=0) return this.$notify({title: '失败',message:res.msg,type: 'error'});
                if(!res.msg) {
                    this.$notify({title: '成功',message: "SUCCESS",type: 'success'});
                    _this.$router.go(-1)
                    return 
                }
                if(res.msg) return this.$alert(res.msg, '提示', {confirmButtonText: '我已知晓',callback:action=>{
                    _this.$router.go(-1)
                }});
                
            })
        },
        //删除记录
        handleClear(index){
            this.form.logs.splice(index,1)
        },

        //清除本地记录
        clearAll(){
            this.form.logs = []
            this.clearRules()
        },
        handleNav(status) {
            switch(status) {
                case "clear":
                    //window.location.reload()
                    this.clearAll()
                    break;
                case "save":
                    if(!this.form.lessonId || !this.video.url) return this.$notify({title: '成功',message: "SUCCESS",type: 'success'});
                    this.$confirm('是否确定保存？').then(res=>{
                        this.req_save()
                    })
                    break;
                default:
                    break;


            }
        },
        handleUseLesson(data) {
            let obj = {template_id:data.templateId,lesson_id:data.id,teacher_id:data.teacherId}
            this.$router.push({name:'screen_lesson',query:obj})
            this.isSearch = false
        },

        //每页条数
        onSize(val){
            this.query.size=val
            this.init()
        },
        onCurrPage(val){
            this.query.page = val
            this.init()
        }
        
    }
}
</script>
<style scoped lang="less">
.seui-adblock {
  width: 100%;
  padding-bottom: 50%;
  background-image: url(~@/assets/imgs/video-feed/pc/vid-bg.png);
  background-size: 100% 100%;
  position: relative;

  button {
    border: 0;
    background-color: transparent;
  }

  .upload-btn {
    label {
      cursor: pointer;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }

  #file-name {
    margin-left: 10px;
  }

  .btn-row {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    padding-left: 1.46rem;
    width: 100%;
    height: 10%;
    position: absolute;
    left: 0;
    bottom: 4.43rem;
    .icon-bg {
      width: 1.25rem;
      height: 1.25rem;
      background-size: 100% 100%;
    }
    img {
      display: block;
      width: 1.25rem;
    }
    button {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      opacity: 1;
      transition: all 0.2s ease;
      span {
        margin-left: 0.94rem;
        white-space: nowrap;
      }
    }
    button:hover {
      opacity: 0.85;
    }
    button:active {
      opacity: 1;
      box-shadow: 0.11rem 0.26rem 0.31rem rgba(255, 255, 255, 0.1);
    }
  }
  .upload-btn {
    width: 20%;
    min-width: fit-content;
    height: 100%;

    background: #ff8a00;
    border-radius: 0.52rem;

    // font-size: 0.94rem;
    font-weight: 400;
    color: #ffffff;
    .icon-bg {
      background-image: url(~@/assets/imgs/video-feed/pc/upload.png);
    }
  }

  .upload-btn-color {
    width: 20%;
    min-width: fit-content;
    height: 100%;
    background: #ffffff;
    border-radius: 0.52rem;
    // font-size: 0.94rem;
    font-weight: 400;
    color: #ff8a00;
    margin-left: 2.6rem;
    .icon-bg {
      background-image: url(~@/assets/imgs/video-feed/pc/upload-color.png);
    }
  }
}
.speak {
    margin-bottom:15px;
    margin-right: 15px;
    .clear {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 13px;
        color: #666;
        cursor: pointer;
    }
    .tool {
        margin-bottom: 10px;
        color: #666;
        font-size: 12px;
    }
}

// mobile
.mobile_submit {
    position:fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    height: 60px;

}
</style>