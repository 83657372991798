<template>
    <div class="report" ref="report">
        <div class="f page d-flex a-center j-end">
            <div class="d-flex a-center" style="margin-right:70px;">
                <!-- <div class="vertical-text f-text" style="margin-right: 25px;letter-spacing: 6px;"></div> -->
                <div class="vertical-text f-text" style="letter-spacing: 6px;">智慧教研教师评课报告</div>
            </div>
        </div>

        <div class="line page bg">
            <div class="position-relative" style="height: 50%;">
                <div class="title">总得分</div>
                <TotalComponent :value="total" :people="people"/>
            </div>
            <div class="position-relative" style="height: 50%;">
                <div class="title" style="top: -70px;">评课分类分析</div>
                <!-- <OneSunChart :data="one_sun_options.data"/> -->
                <OneRadarChart :tooltip="one_radar_options.tooltip" :title="one_radar_options.title" :indicator="one_radar_options.dataset.indicator" :series="one_radar_options.series"/>

            </div>
        </div>

        <div class=" bg position-relative" style="height: 400px;">
            <div class="title" style="height: 10%;">得分分析</div>
            <div class="position-relative" style="height: 90%;">
                <PicBar :data="rule_obj"/>
            </div>
        </div>

        <div class=" bg position-relative">
            <!-- <div class="title" style="height: 10%;"></div> -->
            <div class="position-relative">
                <el-table
                    :data="rule_lists"
                    style="width: 100%">
                    <el-table-column prop="name" label="指标名称" width="80"></el-table-column>
                    <el-table-column prop="expertScore" label="专家评价" width="80" align="center"></el-table-column>
                    <el-table-column prop="machineScore" label="AI评价" width="80" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.machineScore }}/{{ scope.row.maxScore }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="" label="AI打分依据">
                        <template slot-scope="scope">
                            <div style="white-space: pre-line;" v-html="scope.row.remark"></div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <div class="bg position-relative" style="margin-top: 30px;">
            <!-- <div class="title" style="height: 10%;"></div> -->
            <div class="position-relative">
                <el-table
                    :data="summary_lists"
                    :show-header="false"
                    style="width: 100%">
                    <el-table-column prop="name" label="" width="100px"></el-table-column>
                    <el-table-column prop="value" label=""></el-table-column>
                </el-table>
            </div>
        </div>
        
        <!-- <div class="line page bg">
            <div style="height: 7%;"></div>
            <div class="position-relative" style="height: 43%;">
                <div class="title" style="top: -30px;">教师指标分析</div>
                <OneRadarChart :indicator="one_radar_options.dataset.indicator" :series="one_radar_options.series"/>
            </div>
            <div class="position-relative" style="height: 49%;">
                <div class="title" style="top: 20px;">得分分析</div>
                <OnePicbarChart :xAxisData="one_picbar_options.xAxis" :seriesData="one_picbar_options.series"/>
            </div>
        </div> -->

        <!-- <div class="position-relative" style="min-height:841.89px ;">
            <div class="d-flex a-center j-center" style="padding: 15px 0;font-size: 20px; font-weight: 600;">评课维度</div>
            <div style="padding:0 15px;">
                <Group v-for="item,index in rules" :key="index" 
                    :name="item.groupName"
                    :list="item.children"
                    :open="true"
                    :score="item.score"
                    :disabled="true"
                    :color="$store.state.dict.color[index%$store.state.dict.color.length]"
                    @changeRule="(val)=>changeRule(val,item,index)"
                />
            </div>
        </div> -->
    </div>
</template>
<script>
import Group from '../screen/comp/group.vue'
import TotalComponent from '../screen/comp/total.vue'
import OneSunChart from '../screen/comp/onesunChart.vue'
import {portrait,lesson_chart,template} from '@/request/api/lession.js'
import OneRadarChart from './../screen/comp/oneradarChart.vue'
import OnePicbarChart from './../screen/comp/onepicbarChart.vue'
import PicBar from '../screen/comp/picBar.vue'
export default {
    name:'report',
    components:{
        TotalComponent,
        // OneSunChart,
        OneRadarChart,
        // OnePicbarChart,
        // Group,
        PicBar,
    },
    data(){
        return{
            teacher_id:'',
            lesson_id:'',
            total:0,
            people:0,
            one_sun_options:{
                data:[]
            },
            one_radar_options:{
                series:[],
                dataset:{
                    indicator:[]
                },
                title:['专家评价','AI评价','汇总'],
                tooltip:{}
            },
            one_picbar_options:{
                series:[],
                xAxis:[]
            },
            rules:[],
            rule_obj:{},//
            rule_lists:[],//ai打分
            summary_lists:[
                {name:'建议',value:'无'},
                {name:'总结',value:'无'}
            ]
        }
    },
    created(){
        const r = this.$route.query
        this.teacher_id = r.teacher_id||''
        this.lesson_id = r.lesson_id||''
        
    },
    mounted(){
        this.init()
    },
    methods:{
        init(){
            this.get_portrait()
            this.get_radar()
        },
        downPdf(){
            const loading = this.$loading({
                lock: true,
                text: '下载报表中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.1)'
            });
            var timer = setTimeout(()=>{
                loading.close();
                clearTimeout(timer)
                this.getPdf(this.$refs.report,'智慧教研教师评课报告')
            },10*1000)
        },
        async get_portrait(){
            portrait(this.lesson_id).then(res=>{
                this.one_sun_options.data = res.arr
                this.total = res.finalScore
                this.rules = res.complexRules
                this.people = res.res.data.reviewCount
                this.rule_obj = res.rule_obj
                this.rule_lists = res.rule

                this.one_radar_options.series =[
                    {type:"radar",areaStyle: {opacity:0.2},name: this.one_radar_options.title[0],data:[{name:this.one_radar_options.title[0],value:res.leida2.expert}]},
                    {type:"radar",areaStyle: {opacity:0.2},name:this.one_radar_options.title[1],data:[{name:this.one_radar_options.title[1],value:res.leida2.machine}]},
                    {type:"radar",areaStyle: {opacity:0.2},name:this.one_radar_options.title[2],data:[{name:this.one_radar_options.title[2],value:res.leida2.complex}]},
                ]
                this.one_radar_options.dataset.indicator = res.leida2.indicator
                this.one_radar_options.tooltip = {
                    show:true,
                    formatter:function(val){
                        let str = '<div style="">'+val.seriesName+'</div>';
                        val.value.forEach((item,index)=>{
                            str+='<div style="display:flex;justify-center:space-between;"><span style="flex:1;color:#777;">'+res.leida2.indicator[index].name+'</span><span style="margin-left:20px">'+item+'/'+res.leida2.indicator[index].max+'</span></div>'
                        })
                        return str;
                    }
                }
                //建议/总结
                let summary = res.res.data.summary
                if(summary) {
                    this.summary_lists[0].value = summary.suggest
                    this.summary_lists[1].value = summary.summary
                }
            })
        },
        async get_radar(){
            lesson_chart(this.lesson_id).then(res=>{
                this.one_radar_options.dataset.indicator = res.indicator_data
                this.one_radar_options.series.push({
                    name: '得分',
                    type: 'radar',
                    data: [
                        {
                            value: res.radar_data,
                            name: '得分'
                        }
                    ]
                
                })

                this.one_picbar_options.xAxis = res.group_name_lists
                this.one_picbar_options.series.push({
                    name: 'hill',
                    type: 'pictorialBar',
                    barCategoryGap: '-130%',
                    // symbol: 'path://M0,10 L10,10 L5,0 L0,10 z',
                    symbol: 'path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z',
                    itemStyle: {
                        opacity: 0.5
                    },
                    emphasis: {
                        itemStyle: {
                            opacity: 1
                        }
                    },
                    data: res.radar_data,
                    z: 10
                })

            })
        },
        changeRule(){}
    }
}
</script>
<style scoped lang="less">
.report {
    max-width: 592.28px;
    margin: 0 auto;
    background-color: #fff;
    .f {
        width: 100%;
        height: 841.89px;
        background: url(../../assets/report.png) no-repeat center;
        .f-text {
            font-size: 60px;
            color: #015EEA;
            // background: linear-gradient(#00C0FA,#015EEA);  
            // -webkit-background-clip: text;  
            // color: transparent;  
        }
    }
}
.title {
    position: absolute;
    left: 0;
    top: 20px;
    right: 0;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
}

.page {
    max-width: 592.28px;
    height: 841.89px;
}

.bg_report {
    background: url(../../assets/report.png) no-repeat center;
}
.bg {
    background: url(../../assets/report_s.png) no-repeat center;
}


.vertical-text {  
    writing-mode: vertical-rl;  
    transform: rotate(360deg);  
}

</style>