<template>
   <div class="nav-view"> 
        <div class="item-view" @click="handleClick('clear')" v-if="$route.name=='screen_lesson'">
            <img src="@/assets/imgs/video-feed/pc/clear-active.png" />
            <span>清除当前课程</span>
        </div>
        <div class="lin" v-if="$route.name=='screen_lesson'"></div>
        <div class="item-view" @click="handleClick('save')" v-if="$route.name=='screen_lesson'">
            <img src="@/assets/imgs/video-feed/pc/save-active.png" />
            <span>保存评课信息</span>
        </div>
        <div class="lin" v-if="$route.name=='screen_lesson'"></div>
        <div :class="{'item-view':true,'active':$route.name=='screen_teacher'}" @click="$router.push({name:'screen_teacher',query:$props.params})">
            <img src="@/assets/imgs/video-feed/pc/teacher-active.png" />
            <span>教师当前画像</span>
        </div>
        <div class="lin"></div>
        <div :class="{'item-view':true,'active':$route.name=='screen_class'}" @click="$router.push({name:'screen_class',query:$props.params})">
            <img src="@/assets/imgs/video-feed/pc/interact-active.png" />
            <span>课程当前画像</span>
        </div>
        <div class="lin"></div>
        <div :class="{'item-view':true,'active':$route.name=='screen_report'}" @click="$router.push({name:'screen_report',query:$props.params})">
            <img src="@/assets/imgs/video-feed/pc/report-active.png" />
            <span>评课报告</span>
        </div>
        <div class="lin" v-if="$route.name=='screen_class'"></div>
        <div v-if="$route.name=='screen_class'" :class="{'item-view':true}" @click="handleClick('reflect')">
            <img src="@/assets/imgs/video-feed/pc/reflect-active.png" />
            <span>教学反思</span>
        </div>
    </div>
</template>

<script>


export default {
    props:{
        params:Object
    },
    data(){
        return{
            
        }
    },
    created(){},
    methods:{
        handleClick(status){
            this.$emit('change',status)
        },
        
    }
}
</script>

<style scoped lang="less">
.nav-view {
    // width: 3.13%;
    background: #ffffff;
    box-shadow: 0rem 0rem 1rem 0rem rgba(96, 163, 255, 0.28);
    border-radius: 1.04rem 0rem 0rem 1.04rem;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    // padding: 1.9rem 0;
    padding: 10px 0;
    box-sizing: border-box;
    // margin-right: 0.5rem;
    .item-view {
        cursor: pointer;
        width: 100%;
        text-align: center;
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 1.5;
        color: #868da2;
        box-sizing: border-box;
        padding: 25px 10px;
        text-align: center;
        user-select: none;
        :hover {
            color: #547bec;
        }
        img {
            width: 1.37rem;
            display: block;
            margin-bottom: 0.61rem;
            text-align: center;
            margin: auto;
            margin-bottom: 8px;
        }
    }
    .item-view.active {
        color: #547bec;
    }
    .lin {
        width: 80%;
        height: 0.05rem;
        background: #e3e4e4;
    }
}
</style>